import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faAccessibleIcon,
  faAccusoft,
  faMeetup,
  // faNode,
  faPadlet
} from '@fortawesome/free-brands-svg-icons'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons'
import {
  faClock,
  faDollar,
  faFile,
  faHeart,
  faHome,
  faMap,
  faShoppingCart,
  faStar,
  faTable,
  faUser,
  faVault
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EcomProfilePersonalInfo from 'components/forms/EcomProfilePersonalInfo'
import EcomProfileOrdersTable from 'components/tables/EcomProfileOrdersTable'
import EcomProfileReviewsTable from 'components/tables/EcomProfileReviewsTable'
import EcomProfileStoresTable from 'components/tables/EcomProfileStoresTable'
import EcomWishlistTable from 'components/tables/EcomWishlistTable'
import { Nav, Tab } from 'react-bootstrap'

interface TabLink {
  id: string
  label: string
  number?: number
  icon: IconProp
  content: JSX.Element
}

const sales_tabs: TabLink[] = [
  {
    id: 'proposals',
    label: 'Proposals',
    icon: faDollar,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'estimates',
    label: 'Estimate',
    icon: faStar,
    content: <EcomWishlistTable />
  },
  {
    id: 'invoices',
    label: 'Invoices',
    icon: faHeart,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'performa-invoice',
    label: 'Performa Invoice',
    icon: faAccessibleIcon,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'credit-notes',
    label: 'Credit Notes',
    icon: faAddressBook,
    content: <EcomProfileOrdersTable />
  }
]

const other: TabLink[] = [
  {
    id: 'files',
    label: 'Files',
    icon: faFile,
    content: <EcomWishlistTable />
  },
  {
    id: 'vault',
    label: 'Vault',
    icon: faVault,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'reminders',
    label: 'Reminders',
    icon: faClock,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'meetings',
    label: 'Meetings',
    icon: faMeetup,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'map',
    label: 'Map',
    icon: faMap,
    content: <EcomProfileOrdersTable />
  }
]

const tabLinks: TabLink[] = [
  {
    id: 'orders',
    label: 'Profile',
    number: 35,
    icon: faShoppingCart,
    content: <EcomProfileOrdersTable />
  },
  {
    id: 'review',
    label: 'Contacts',
    number: 24,
    icon: faStar,
    content: <EcomProfileReviewsTable />
  },
  {
    id: 'wishlist',
    label: 'Notes',
    icon: faHeart,
    content: <EcomWishlistTable />
  },
  {
    id: 'stores',
    label: 'Statements',
    icon: faHome,
    content: <EcomProfileStoresTable />
  },
  {
    id: 'subscriptions',
    label: 'Subscriptions',
    icon: faUser,
    content: <EcomProfilePersonalInfo />
  },
  {
    id: 'expenses',
    label: 'Expenses',
    icon: faDollar,
    content: <EcomProfilePersonalInfo />
  },
  {
    id: 'agreements',
    label: 'Agreements',
    icon: faAccusoft,
    content: <EcomProfilePersonalInfo />
  },
  {
    id: 'projects',
    label: 'Projects',
    icon: faPadlet,
    content: <EcomProfilePersonalInfo />
  },
  {
    id: 'tasks',
    label: 'Task',
    icon: faTable,
    content: <EcomProfilePersonalInfo />
  },
  {
    id: 'tickets',
    label: 'Tickets',
    icon: faAccusoft,
    content: <EcomProfilePersonalInfo />
  }
]

const ProfileDetailsTab = () => {
  return (
    <Tab.Container id="basic-tabs-example" defaultActiveKey="contact">
      <Nav variant="underline" className="mb-3 mt-3 text-right">
        <Nav.Item>
          <Nav.Link eventKey="contact">Contact</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sales">Sales</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="other">Other</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="sales">
          <Tab.Container defaultActiveKey="proposals">
            <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
              {sales_tabs.map(item => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id} className="text-nowrap">
                    <FontAwesomeIcon icon={item.icon} className="me-2" />
                    {item.label}{' '}
                    {item.number && (
                      <span className="text-body-tertiary fw-normal">
                        ({item.number})
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content>
              {sales_tabs.map(item => (
                <Tab.Pane
                  key={item.id}
                  eventKey={item.id}
                  // title={
                  //   <>
                  //     <FontAwesomeIcon icon={item.icon} className="me-2" />
                  //     {item.label}{' '}
                  //     {item.number && (
                  //       <span className="text-body-tertiary fw-normal">({item.number})</span>
                  //     )}
                  //   </>
                  // }
                >
                  {item.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Tab.Pane>
        <Tab.Pane eventKey="contact">
          <Tab.Container defaultActiveKey="orders">
            <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
              {tabLinks.map(item => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id} className="text-nowrap">
                    <FontAwesomeIcon icon={item.icon} className="me-2" />
                    {item.label}{' '}
                    {item.number && (
                      <span className="text-body-tertiary fw-normal">
                        ({item.number})
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content>
              {tabLinks.map(item => (
                <Tab.Pane
                  key={item.id}
                  eventKey={item.id}
                  // title={
                  //   <>
                  //     <FontAwesomeIcon icon={item.icon} className="me-2" />
                  //     {item.label}{' '}
                  //     {item.number && (
                  //       <span className="text-body-tertiary fw-normal">({item.number})</span>
                  //     )}
                  //   </>
                  // }
                >
                  {item.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Tab.Pane>
        <Tab.Pane eventKey="other">
          <Tab.Container defaultActiveKey="files">
            <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
              {other.map(item => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id} className="text-nowrap">
                    <FontAwesomeIcon icon={item.icon} className="me-2" />
                    {item.label}{' '}
                    {item.number && (
                      <span className="text-body-tertiary fw-normal">
                        ({item.number})
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content>
              {other.map(item => (
                <Tab.Pane
                  key={item.id}
                  eventKey={item.id}
                  // title={
                  //   <>
                  //     <FontAwesomeIcon icon={item.icon} className="me-2" />
                  //     {item.label}{' '}
                  //     {item.number && (
                  //       <span className="text-body-tertiary fw-normal">({item.number})</span>
                  //     )}
                  //   </>
                  // }
                >
                  {item.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default ProfileDetailsTab
