import React, { useState } from 'react'
import propTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const Model = props => {
  const [submitTxt, setSubmitTxt] = useState('Save Changes')
  const [modelSize, setModelSize] = useState('sm')
  const [noFooter, setNoFooter] = useState(false)

  React.useEffect(() => {
    if (props.submitTxt) {
      setSubmitTxt(props.submitTxt)
    }

    if (props.size) {
      setModelSize(props.size)
    }

    if (props.noFooter) {
      setNoFooter(props.noFooter)
    }
  }, [])

  return (
    <>
      <Modal
        size={modelSize}
        show={props.show}
        onHide={props.setClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.modelHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        {!noFooter && (
          <Modal.Footer>
            <Button variant="secondary" onClick={props.setClose}>
              Close
            </Button>
            <Button onClick={props.onClick} variant="primary">
              {submitTxt}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

Model.propTypes = {
  body: propTypes.node,
  modelHeader: propTypes.string,
  onClick: propTypes.func,
  setClose: propTypes.func,
  show: propTypes.bool,
  noFooter: propTypes.bool,
  submitTxt: propTypes.string,
  size: propTypes.string
}

export default Model
