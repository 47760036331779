import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable'
import React from 'react'

const EcomTopRegions = () => {
  return (
    <>
      <div className="mb-5 mt-0">
        <h3> Top performing customers</h3>
        <p className="text-body-tertiary">
          {' '}
          Which customers gained most of the revenue
        </p>
      </div>
      <EcomTopRegionsTable />
    </>
  )
}

export default EcomTopRegions
