import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'
import { Col, Row } from 'react-bootstrap'

import EcoimDefaultAddressCard from './components/EcoimDefaultAddressCard'
import EcomProfileCard from './components/EcomProfileCard'
import ProfileDetailsTab from './components/ProfileDetailsTab'
import {
  faEnvelope,
  faKey,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import apiRequest from 'apiRequest'

export type Customer = {
  id: string
  name: string
  vat_number: string
  phone: string
  currency: string
  address: string
  last_seen: string
  default_language: string
  last_order?: string
}

const Client = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [preload, setPreload] = useState(true)
  const [client, setClient] = useState<Customer>({
    id: '',
    name: '',
    vat_number: '',
    phone: '',
    currency: '',
    address: '',
    last_seen: '',
    default_language: '',
    last_order: ''
  })

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/get',
      true
    )
    API_REQUEST.send(
      { client: id },
      (data: { status: string; message: string; data: Customer }) => {
        if (data.status == 'success') {
          setClient(data.data)
        } else {
          navigate('/module/clients/list', { replace: true })
        }
        setPreload(false)
      }
    )
  }, [id])

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <Row className="align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Profile</h2>
        </Col>
        <Col xs="auto" className="d-flex flex-wrap gap-2 gap-sm-3">
          <Button
            variant="phoenix-info"
            startIcon={<FontAwesomeIcon className="me-2" icon={faEnvelope} />}
          >
            Send Email
          </Button>
          <Button
            variant="phoenix-danger"
            startIcon={<FontAwesomeIcon className="me-2" icon={faTrashAlt} />}
          >
            Delete customer
          </Button>
          <Button
            variant="phoenix-secondary"
            startIcon={<FontAwesomeIcon className="me-2" icon={faKey} />}
          >
            Reset password
          </Button>
        </Col>
      </Row>
      <Row className="g-3 mb-6">
        <Col xs={12} lg={8}>
          <EcomProfileCard client={client} preload={preload} />
        </Col>
        <Col xs={12} lg={4}>
          <EcoimDefaultAddressCard client={client} preload={preload} />
        </Col>
      </Row>
      <ProfileDetailsTab />
    </div>
  )
}

export default Client
