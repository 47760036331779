import { ColumnDef, flexRender } from '@tanstack/react-table'
import classNames from 'classnames'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import {
  TopRegionsTableDataType,
  topRegionsTableData
} from './TopRegionsTableData'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const columns: ColumnDef<TopRegionsTableDataType>[] = [
  {
    header: 'Customer',
    accessorFn: rowData => rowData.customers.name,
    cell: ({ row }) => {
      const serial = row.index + 1
      const { customers } = row.original
      return (
        <div className="d-flex align-items-center">
          <h6 className="mb-0 me-3">{serial}.</h6>
          <Link to="#!">
            <div className="d-flex justify-content-center">
              <p className="mb-0 ps-3 text-primary fw-bold fs-9">
                {customers.name}
              </p>
            </div>
          </Link>
        </div>
      )
    },
    meta: {
      headerProps: { style: { width: '32%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap align-middle ps-0' }
    }
  },
  {
    header: 'Invoice Number',
    accessorFn: rowData => rowData.invoice_number.number,
    cell: ({ row: { original } }) => {
      const { invoice_number } = original
      return <h6 className="mb-0">${invoice_number.number}</h6>
    },
    meta: {
      headerProps: { style: { width: '17%' } },
      cellProps: { className: 'align-middle' }
    }
  },
  {
    header: 'Amont',
    accessorFn: rowData => rowData.amount.number,
    cell: ({ row: { original } }) => {
      const { amount } = original
      return <h6 className="mb-0">${amount.number}</h6>
    },
    meta: {
      cellProps: { className: 'text-end' },
      headerProps: {
        style: { width: '20%' },
        className: 'text-end align-middle'
      }
    }
  }
]

const EcomTopRegionsTable = () => {
  const table = useAdvanceTable({
    data: topRegionsTableData,
    columns,
    pageSize: 5,
    pagination: true,
    selectionColumnWidth: '30px',
    sortable: true
  })

  const { getRowModel, getFlatHeaders } = table

  return (
    <AdvanceTableProvider {...table}>
      {/* <Scrollbar autoHeight autoHeightMax="100%"> */}
      <div className="scrollbar">
        <Table className="fs-10 mb-0 border-top border-translucent scrollbar">
          <thead>
            <tr>
              {getFlatHeaders().map(header => {
                return (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.headerProps}
                    className={classNames(
                      'sort',
                      header.column.columnDef.meta?.headerProps?.className,
                      {
                        desc: header.column.getIsSorted() === 'desc',
                        asc: header.column.getIsSorted() === 'asc'
                      }
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="py-4">
                <h4 className="mb-0 fw-normal">$377,620</h4>
              </td>
              <td className="text-end py-4">
                <h4 className="mb-0 fw-normal">$236, 456</h4>
              </td>
            </tr>
            {getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <AdvanceTableFooter className="gx-0" navBtn showViewAllBtn={false} />
      {/* </Scrollbar> */}
    </AdvanceTableProvider>
  )
}

export default EcomTopRegionsTable
