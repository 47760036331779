import india from 'assets/img/country/india.png'
import china from 'assets/img/country/china.png'
import usa from 'assets/img/country/usa.png'
import southKorea from 'assets/img/country/south-korea.png'
import vietnam from 'assets/img/country/vietnam.png'
import russia from 'assets/img/country/russia.png'

export type TopRegionsTableDataType = {
  customers: {
    name: string
    flag: string
  }
  invoice_number: {
    number: number
    percantage: string
  }
  amount: {
    number: number
    percantage: string
  }
}

export const topRegionsTableData: TopRegionsTableDataType[] = [
  {
    customers: {
      name: 'John Smith',
      flag: india
    },
    invoice_number: {
      number: 6745,
      percantage: '34.3%'
    },
    amount: {
      number: 7560,
      percantage: '36.9%'
    }
  },
  {
    customers: {
      name: 'Jane Doe',
      flag: china
    },
    invoice_number: {
      number: 50496,
      percantage: '32.8%'
    },
    amount: {
      number: 5465,
      percantage: '23.8%'
    }
  },
  {
    customers: {
      name: 'Alex Johnson',
      flag: usa
    },
    invoice_number: {
      number: 45679,
      percantage: '24.3%'
    },
    amount: {
      number: 3500,
      percantage: '19.7%'
    }
  },
  {
    customers: {
      name: 'Emily Davis',
      flag: southKorea
    },
    invoice_number: {
      number: 36453,
      percantage: '19.7%'
    },
    amount: {
      number: 2200,
      percantage: '9.54%'
    }
  },
  {
    customers: {
      name: 'Michael Brown',
      flag: vietnam
    },
    invoice_number: {
      number: 15007,
      percantage: '11.9%'
    },
    amount: {
      number: 1700,
      percantage: '6.91%'
    }
  },
  {
    customers: {
      name: 'Sarah Miller',
      flag: russia
    },
    invoice_number: {
      number: 54215,
      percantage: '32.9%'
    },
    amount: {
      number: 3844,
      percantage: '7.91%'
    }
  }
]
