export default class apiRequest {
  headers = { 'Content-Type': 'application/json' }
  method = 'POST'
  isJSONStringify = true

  constructor(baseUrl, auth = false) {
    this.baseUrl = baseUrl
    if (auth) {
      this.setHeaders([
        {
          key: 'Authorization',
          value: 'Bearer ' + localStorage.getItem('token')
        }
      ])
    }
  }

  setHeaders(headerArr) {
    headerArr.forEach(headers => {
      this.headers[headers.key] = headers.value
    })
  }

  setMethod(method) {
    this.method = method
  }

  setJSONStringify(status) {
    this.isJSONStringify = status
  }

  async send(request_data = {}, successCallback, endPoint) {
    return fetch(endPoint !== undefined ? endPoint : this.baseUrl, {
      method: this.method,
      headers: this.headers,
      body: this.isJSONStringify ? JSON.stringify(request_data) : request_data
    })
      .then(response => response.json())
      .then(data => {
        if (data.status && data.status == 'denied') {
          localStorage.removeItem('token')
        }
        if (successCallback === undefined) {
          return data
        }

        return successCallback(data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  async sendWithAppUser(request_data = {}, successCallback, endPoint) {
    var custom_headers = {
      'X-App-User': localStorage.getItem('token')
    }

    if (this.isJSONStringify) {
      custom_headers['Content-Type'] = 'application/json'
    } else {
      custom_headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }

    return fetch(endPoint !== undefined ? endPoint : this.baseUrl, {
      method: this.method,
      headers: {
        'Content-Type': 'application/json',
        'X-App-User': localStorage.getItem('token')
      },
      body: this.isJSONStringify ? JSON.stringify(request_data) : request_data
    })
      .then(response => response.json())
      .then(data => {
        if (data.status && data.status == 'denied') {
          localStorage.removeItem('token')
        }

        if (successCallback === undefined) {
          return data
        }
        successCallback(data)
      })
      .catch(error => {
        console.log(error !== undefined ? error : '')
      })
  }
}
