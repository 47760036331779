import AppProvider from 'providers/AppProvider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import BreakpointsProvider from 'providers/BreakpointsProvider'
import SettingsPanelProvider from 'providers/SettingsPanelProvider'
import { RouterProvider } from 'react-router-dom'
import { router } from 'Routes'
import ChatWidgetProvider from 'providers/ChatWidgetProvider'
import toast, { toastConfig } from 'react-simple-toasts'
import 'react-simple-toasts/dist/theme/light.css'
import 'react-simple-toasts/dist/theme/dark.css'
import 'react-simple-toasts/dist/theme/success.css'
import 'react-simple-toasts/dist/theme/info.css'
import 'react-simple-toasts/dist/theme/failure.css'

const avoid_redirect = ['/', '/login', '/forgot-password', '/reset-password']
if (
  localStorage.getItem('token') == null &&
  avoid_redirect.indexOf(window.location.pathname) == -1
) {
  localStorage.setItem('user', '')
  localStorage.setItem('role', '')
  window.location.href = '/'
}

setInterval(() => {
  const toastData = localStorage.getItem('toast')
  if (toastData != null) {
    const toastParsedData: {
      type: string
      message: string
      loading: boolean
      nextText: string
      position: string
      infinite: string
    } = JSON.parse(toastData)
    toastConfig({
      theme: toastParsedData.type,
      position: 'bottom-center'
    })
    const toastElement = toast(toastParsedData.message, {
      loading: toastParsedData.loading,
      duration: toastParsedData.infinite == undefined ? 5000 : null
    })
    if (toastParsedData.nextText != null) {
      setTimeout(() => {
        toastElement.update(toastParsedData.nextText)
      }, 1000)
    }

    localStorage.removeItem('toast')
  }
}, 1000)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppProvider>
      <SettingsPanelProvider>
        <ChatWidgetProvider>
          <BreakpointsProvider>
            <RouterProvider router={router} />
          </BreakpointsProvider>
        </ChatWidgetProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </React.StrictMode>
)
