import ContactsBySource from 'components/modules/crm/ContactsBySource'
import NewUsersAndLeads from 'components/modules/crm/NewUsersAndLeads'
import { Col, Row } from 'react-bootstrap'
import EcomTopRegions from 'components/modules/e-commerce/dashboard/EcomTopRegions'
import TodoList from 'components/modules/project-management/todo-list/TodoList'
import Events from 'components/modules/events/Events'
import { events } from 'data/eventsData'
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'components/base/DatePicker'

const ClientDashboard = () => {
  return (
    <>
      <Row className="mb-4 gy-3 justify-content-between">
        <Row xxl={12}>
          <Col xxl={10}>
            <WidgetsSectionTitle
              title="Customer Dashboard"
              subtitle="New Users & Leads"
              icon={faUsers}
              className="mb-5 mt-3"
            />
          </Col>
          <Col xxl={2} sm={12} md={12} lg={12} xl={12}>
            <DatePicker
              options={{
                mode: 'range',
                minDate: 'today',
                dateFormat: 'Y-m-d'
              }}
              placeholder="Select date"
            />
          </Col>
        </Row>
        <Col xxl={8}>
          <NewUsersAndLeads />
          <ContactsBySource />
        </Col>
        <Col xxl={4}>
          <EcomTopRegions />
        </Col>
      </Row>
      <Row className="mt-8">
        <Col xxl={7}>
          <TodoList />
        </Col>
        <Col xxl={5}>
          <Events events={events} title="Upcoming Client Meetings" />
        </Col>
      </Row>
    </>
  )
}

export default ClientDashboard
