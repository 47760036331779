import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChess, faDove } from '@fortawesome/free-solid-svg-icons'

export interface ActivityTimelineData {
  id: number
  oppositeContent: {
    date: string
    time: string
  }
  content: {
    title: string
    user: string
    details: string
  }
  separator: {
    icon: IconProp
  }
}

export const activityTimelineData: ActivityTimelineData[] = [
  {
    id: 1,
    oppositeContent: {
      date: '01 DEC, 2023',
      time: '10:30 AM'
    },
    content: {
      title: 'Phoenix Template: Unleashing Creative Possibilities',
      user: 'Shantinon Mekalan',
      details:
        'Discover limitless creativity with the Phoenix template! Our latest update offers an array of innovative features and design options.'
    },
    separator: {
      icon: faChess
    }
  },
  {
    id: 2,
    oppositeContent: {
      date: '05 DEC, 2023',
      time: '12:30 AM'
    },
    content: {
      title: 'Empower Your Digital Presence: The Phoenix Template Unveiled',
      user: 'Bookworm22',
      details:
        'Unveiling the Phoenix template, a game-changer for your digital presence. With its powerful features and sleek design,'
    },
    separator: {
      icon: faDove
    }
  }
]
