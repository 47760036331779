import india from 'assets/img/country/india.png'
import china from 'assets/img/country/china.png'
import usa from 'assets/img/country/usa.png'
import southKorea from 'assets/img/country/south-korea.png'
import vietnam from 'assets/img/country/vietnam.png'
import russia from 'assets/img/country/russia.png'
import australia from 'assets/img/country/australia.png'
import england from 'assets/img/country/england.png'
import indonesia from 'assets/img/country/indonesia.png'
import japan from 'assets/img/country/japan.png'

export type TopRegionsTableDataType = {
  customers: {
    name: string
    flag: string
  }
  users: {
    number: number
    percantage: string
  }
  transactions: {
    number: number
    percantage: string
  }
  revenue: {
    number: number
    percantage: string
  }
}

export const topRegionsTableData: TopRegionsTableDataType[] = [
  {
    customers: {
      name: 'John Smith',
      flag: india
    },
    users: {
      number: 92896,
      percantage: '41.6%'
    },
    transactions: {
      number: 6745,
      percantage: '34.3%'
    },
    revenue: {
      number: 7560,
      percantage: '36.9%'
    }
  },
  {
    customers: {
      name: 'Jane Doe',
      flag: china
    },
    users: {
      number: 50496,
      percantage: '32.8%'
    },
    transactions: {
      number: 5465,
      percantage: '23.8%'
    },
    revenue: {
      number: 6532,
      percantage: '26.5%'
    }
  },
  {
    customers: {
      name: 'Alex Johnson',
      flag: usa
    },
    users: {
      number: 45679,
      percantage: '24.3%'
    },
    transactions: {
      number: 3500,
      percantage: '19.7%'
    },
    revenue: {
      number: 5432,
      percantage: '16.9%'
    }
  },
  {
    customers: {
      name: 'Emily Davis',
      flag: southKorea
    },
    users: {
      number: 36453,
      percantage: '19.7%'
    },
    transactions: {
      number: 2200,
      percantage: '9.54%'
    },
    revenue: {
      number: 4673,
      percantage: '11.6%'
    }
  },
  {
    customers: {
      name: 'Michael Brown',
      flag: vietnam
    },
    users: {
      number: 15007,
      percantage: '11.9%'
    },
    transactions: {
      number: 1700,
      percantage: '6.91%'
    },
    revenue: {
      number: 2456,
      percantage: '10.2%'
    }
  },
  {
    customers: {
      name: 'Sarah Miller',
      flag: russia
    },
    users: {
      number: 54215,
      percantage: '32.9%'
    },
    transactions: {
      number: 3844,
      percantage: '7.91%'
    },
    revenue: {
      number: 3254,
      percantage: '12.4%'
    }
  },
  {
    customers: {
      name: 'David Wilson',
      flag: australia
    },
    users: {
      number: 54789,
      percantage: '12.7%'
    },
    transactions: {
      number: 3244,
      percantage: '14.0%'
    },
    revenue: {
      number: 3215,
      percantage: '5.72%'
    }
  },
  {
    customers: {
      name: 'Laura Moore',
      flag: england
    },
    users: {
      number: 14785,
      percantage: '12.9%'
    },
    transactions: {
      number: 1155,
      percantage: '32.91%'
    },
    revenue: {
      number: 4745,
      percantage: '10.2%'
    }
  },
  {
    customers: {
      name: 'Chris Taylor',
      flag: indonesia
    },
    users: {
      number: 32156,
      percantage: '32.2%'
    },
    transactions: {
      number: 8944,
      percantage: '12.0%'
    },
    revenue: {
      number: 2456,
      percantage: '23.2%'
    }
  },
  {
    customers: {
      name: 'Olivia Anderson',
      flag: japan
    },
    users: {
      number: 12547,
      percantage: '12.7%'
    },
    transactions: {
      number: 2165,
      percantage: '14.91%'
    },
    revenue: {
      number: 2541,
      percantage: '23.2%'
    }
  }
]
