import React from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'react-bootstrap'
// import FalconCardHeader from 'components/common/FalconCardHeader'
// import CardDropdown from 'components/common/CardDropdown'
import weatherIcon from 'assets/img/icons/weather-icon.png'
import Flex from 'components/common/Flex'

const Weather = ({
  data: {
    city,
    condition,
    precipitation,
    temperature,
    highestTemperature,
    lowestTemperature
  },
  ...rest
}) => (
  <Card {...rest} className="pt-0">
    <Card.Body className="">
      <Row className="g-0 h-100 align-items-center">
        <Col as={Flex} alignItems="center">
          <img className="me-3" src={weatherIcon} alt="" height="60" />
          <div>
            <h4 className="mb-2">{city}</h4>
            <div className="fs-30 fw-semibold">
              <div className="text-warning">{condition}</div>
              Precipitation: {precipitation}
            </div>
          </div>
        </Col>
        <Col xs="auto" className="text-center ps-2">
          <div className="fs-5 fw-normal font-sans-serif text-primary mb-1 lh-1">
            {temperature}°
          </div>
          <div className="fs-10 text-800">
            {highestTemperature}° / {lowestTemperature}°
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
)

Weather.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default Weather
