// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from 'components/base/Button'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'
import { Col, Row } from 'react-bootstrap'

// import { faKey, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Form, FormControl, InputGroup, Table, Tab, Nav } from 'react-bootstrap'
import ReactSelect from 'components/base/ReactSelect'

// import apiRequest from 'apiRequest'

export type Customer = {
  id: string
  name: string
  vat_number: string
  phone: string
  currency: string
  address: string
  last_seen: string
  default_language: string
  last_order?: string
}

const Client = () => {
  const [key, setKey] = useState<string | null>('home')
  const [addNew, setAddNew] = useState(false)

  //   const navigate = useNavigate()

  const { id } = useParams()
  //   const [preload, setPreload] = useState(true)
  //   const [client, setClient] = useState<Customer>({
  //     id: '',
  //     name: '',
  //     vat_number: '',
  //     phone: '',
  //     currency: '',
  //     address: '',
  //     last_seen: '',
  //     default_language: '',
  //     last_order: ''
  //   })

  useEffect(() => {
    console.log(id)
    // const API_REQUEST = new apiRequest(
    //   'https://demo.globalcloudmedia.com/client/get',
    //   true
    // )
    // API_REQUEST.send(
    //   { client: id },
    //   (data: { status: string; message: string; data: Customer }) => {
    //     if (data.status == 'success') {
    //       setClient(data.data)
    //     } else {
    //       navigate('/module/clients', { replace: true })
    //     }
    //     setPreload(false)
    //   }
    // )
  }, [id])

  const AddNewRow = () => {
    setAddNew(true)
  }

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <Row className="align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Create New Proforma Invoice</h2>
        </Col>
        {/* <Col xs="auto" className="d-flex flex-wrap gap-2 gap-sm-3">
        <Button
          variant="phoenix-danger"
          startIcon={<FontAwesomeIcon className="me-2" icon={faTrashAlt} />}
        >
          Delete customer
        </Button>
        <Button
          variant="phoenix-secondary"
          startIcon={<FontAwesomeIcon className="me-2" icon={faKey} />}
        >
          Reset password
        </Button>
      </Col> */}
      </Row>
      <Row
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingBottom: '25px',
          borderRadius: '10px',
          backgroundColor: '#fff'
        }}
        className="light__bg-light dark__text-gray-100 dark__bg-gray-1000"
        bg="light"
      >
        <Row className="g-3 mb-4">
          <Col xs={6} sm={12} md={12} lg={6}>
            <Row className="mt-0">
              <Col xs={12}>
                <Form.Label style={{ paddingLeft: 0 }}>
                  Select Customer
                </Form.Label>
                <ReactSelect
                  options={[
                    {
                      value: 'Massachusetts Institute of Technology',
                      label: 'Massachusetts Institute of Technology'
                    },
                    {
                      value: 'University of Chicago',
                      label: 'University of Chicago'
                    },
                    {
                      value: 'GSAS Open Labs At Harvard',
                      label: 'GSAS Open Labs At Harvard'
                    },
                    {
                      value: 'California Institute of Technology',
                      label: 'California Institute of Technology'
                    }
                  ]}
                  placeholder="Select organizer..."
                  className="small"
                  classNamePrefix="react-select"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={6}>
                <h5>Bill To</h5>
                <p style={{ fontSize: '14px' }}>Global Cloud Media (pvt) Ltd</p>
                <p style={{ fontSize: '14px' }}>
                  No 20,
                  <br /> Old Kasbawa Road,
                  <br /> Nugegoda
                </p>
              </Col>
              <Col xs={6}>
                <h5>Ship to</h5>
                <p style={{ fontSize: '14px' }}>Global Cloud Media (pvt) Ltd</p>
                <p style={{ fontSize: '14px' }}>
                  No 20,
                  <br /> Old Kasbawa Road,
                  <br /> Nugegoda
                </p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">INV-#</InputGroup.Text>
                  <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Invoice Date
                  </InputGroup.Text>
                  <FormControl
                    type="date"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Due Date</InputGroup.Text>
                  <FormControl
                    type="date"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="mt-0" style={{ top: 0, marginBottom: 10 }}>
              <Col xs={12}>
                <Form.Label style={{ paddingLeft: 0 }}>
                  Select Customer
                </Form.Label>
                <ReactSelect
                  options={[
                    {
                      value: 'Massachusetts Institute of Technology',
                      label: 'Massachusetts Institute of Technology'
                    },
                    {
                      value: 'University of Chicago',
                      label: 'University of Chicago'
                    },
                    {
                      value: 'GSAS Open Labs At Harvard',
                      label: 'GSAS Open Labs At Harvard'
                    },
                    {
                      value: 'California Institute of Technology',
                      label: 'California Institute of Technology'
                    }
                  ]}
                  isMulti
                  placeholder="Select organizer..."
                />
              </Col>
            </Row>
            <Row className="mt-3" style={{ marginBottom: 10 }}>
              <Col xs={12}>
                <Form.Label style={{ paddingLeft: 0 }}>
                  Select Customer
                </Form.Label>
                <ReactSelect
                  options={[
                    {
                      value: 'Massachusetts Institute of Technology',
                      label: 'Massachusetts Institute of Technology'
                    },
                    {
                      value: 'University of Chicago',
                      label: 'University of Chicago'
                    },
                    {
                      value: 'GSAS Open Labs At Harvard',
                      label: 'GSAS Open Labs At Harvard'
                    },
                    {
                      value: 'California Institute of Technology',
                      label: 'California Institute of Technology'
                    }
                  ]}
                  placeholder="Select organizer..."
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Currency</InputGroup.Text>

                  <Form.Select
                    id="default_language"
                    //   onChange={handleSelectChange}
                  >
                    <option value="male">LKR</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Created By
                  </InputGroup.Text>
                  <Form.Select
                    id="default_language"
                    //   onChange={handleSelectChange}
                  >
                    <option value="male">Global Cloud Media</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Row className="mt-1">
                <Col xs={12}>
                  <Form.Label style={{ paddingLeft: 0 }}>Admin Note</Form.Label>
                  <FormControl
                    as={'textarea'}
                    placeholder="Username"
                    aria-label="Username"
                    rows={5}
                    aria-describedby="basic-addon1"
                    className="w-100"
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1 mb-5">
          <Tab.Container
            id="controlled-tab-example"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Nav variant="tabs" className="mb-1" style={{ margin: 0 }}>
              <Nav.Item
                style={{
                  border: '1px solid #dee2e6',
                  padding: '1px',
                  width: '10%',
                  textDecoration: 'none',
                  textAlign: 'center',
                  margin: 0
                }}
              >
                <Nav.Link eventKey="home">Product Lines</Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  border: '1px solid #dee2e6',
                  padding: '1px',
                  width: '10%',
                  textDecoration: 'none',
                  textAlign: 'center'
                }}
              >
                <Nav.Link eventKey="profile">Optional Products</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="home">
                <Table hover size="sm">
                  <thead className="dark__text-gray-100 dark__bg-gray-900">
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Description</th>
                      <th>Item Qty</th>
                      <th>Free Qty</th>
                      <th>Rate</th>
                      <th>Exp.Date</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>ASTROCORT 1% | 15g</td>
                      <td>-</td>
                      <td>10 Qty</td>
                      <td>1 Qty</td>
                      <td>1,500</td>
                      <td>2024-05-04</td>
                      <td style={{ textAlign: 'right' }}>15,000.00</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>ENDERM-GM CREAM | 15g</td>
                      <td>-</td>
                      <td>10 Qty</td>
                      <td>1 Qty</td>
                      <td>1,500</td>
                      <td>2024-05-04</td>
                      <td style={{ textAlign: 'right' }}>15,000.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {addNew && (
                      <tr>
                        <td colSpan={2}>
                          <ReactSelect
                            options={[
                              {
                                value: 'Massachusetts Institute of Technology',
                                label: 'Massachusetts Institute of Technology'
                              },
                              {
                                value: 'University of Chicago',
                                label: 'University of Chicago'
                              },
                              {
                                value: 'GSAS Open Labs At Harvard',
                                label: 'GSAS Open Labs At Harvard'
                              },
                              {
                                value: 'California Institute of Technology',
                                label: 'California Institute of Technology'
                              }
                            ]}
                            placeholder="Select organizer..."
                            // onChange={}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Description"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            placeholder="Item Qty"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Free Qty"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Rate"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="date"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                            readOnly={true}
                          />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <FormControl
                            type="number"
                            placeholder="Total"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td>
                        <p
                          onClick={AddNewRow}
                          style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            color: 'blue',
                            fontSize: '13px'
                          }}
                          className="dark__text-gray-100 dark__bg-gray-900"
                        >
                          {' '}
                          + Add Item
                        </p>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: 'right' }}></td>
                    </tr>
                  </tfoot>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey="profile">
                <Table hover size="sm">
                  <thead className="dark__text-gray-100 dark__bg-gray-900">
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Description</th>
                      <th>Item Qty</th>
                      <th>Free Qty</th>
                      <th>Rate</th>
                      <th>Exp.Date</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>ASTROCORT 1% | 15g</td>
                      <td>-</td>
                      <td>10 Qty</td>
                      <td>1 Qty</td>
                      <td>1,500</td>
                      <td>2024-05-04</td>
                      <td style={{ textAlign: 'right' }}>15,000.00</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>ENDERM-GM CREAM | 15g</td>
                      <td>-</td>
                      <td>10 Qty</td>
                      <td>1 Qty</td>
                      <td>1,500</td>
                      <td>2024-05-04</td>
                      <td style={{ textAlign: 'right' }}>15,000.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {addNew && (
                      <tr>
                        <td colSpan={2}>
                          <ReactSelect
                            options={[
                              {
                                value: 'Massachusetts Institute of Technology',
                                label: 'Massachusetts Institute of Technology'
                              },
                              {
                                value: 'University of Chicago',
                                label: 'University of Chicago'
                              },
                              {
                                value: 'GSAS Open Labs At Harvard',
                                label: 'GSAS Open Labs At Harvard'
                              },
                              {
                                value: 'California Institute of Technology',
                                label: 'California Institute of Technology'
                              }
                            ]}
                            placeholder="Select organizer..."
                            // onChange={}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Description"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            placeholder="Item Qty"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Free Qty"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="text"
                            placeholder="Rate"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="date"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                            readOnly={true}
                          />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <FormControl
                            type="number"
                            placeholder="Total"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="w-100"
                            style={{ width: '100%' }}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td>
                        <p
                          onClick={AddNewRow}
                          style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            color: 'blue',
                            fontSize: '13px'
                          }}
                        >
                          {' '}
                          + Add Item
                        </p>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: 'right' }}></td>
                    </tr>
                  </tfoot>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>
        <Row className="mt-1">
          <Col xs={8}>
            <Form.Label style={{ paddingLeft: 0 }}>
              Terms and Conditions
            </Form.Label>
            <FormControl
              as={'textarea'}
              placeholder="Username"
              aria-label="Username"
              rows={5}
              aria-describedby="basic-addon1"
              className="w-100"
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={4}>
            <Table hover size="sm">
              <tbody>
                <tr>
                  <th style={{ width: '75%' }}>Sub Total</th>
                  <td style={{ textAlign: 'right' }}>LKR. 30,000.00</td>
                </tr>
                <tr>
                  <th>Discount</th>
                  <td style={{ textAlign: 'right' }}>LKR. 500.00</td>
                </tr>
                <tr>
                  <th>Adjustment</th>
                  <td style={{ textAlign: 'right' }}>LKR. 0.00</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td style={{ textAlign: 'right' }}>LKR. 29,500.00</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default Client
