import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
// import { Link } from 'react-router-dom'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import useAdvanceTable from 'hooks/useAdvanceTable'

import { useEffect, useState } from 'react'

// import apiRequest from 'apiRequest'
// import toast, { toastConfig } from 'react-simple-toasts'

export type Customer = {
  id: string
  amount: string
  customer: string
  project: string
  tags: string
  date: string
  exp_date: string
  refference: string
  status?: string
}

const customersTablecolumns: ColumnDef<Customer>[] = [
  {
    accessorKey: 'id',
    header: 'PI #',
    meta: {
      headerProps: { style: { width: '5%' }, className: 'pe-5 ps-0' },
      cellProps: { className: 'py-0 pe-5 ps-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'customer',
    header: 'Customer',
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-7' },
      cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
    }
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    }
  },
  {
    accessorKey: 'total_tax',
    header: 'Total Tax',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
      cellProps: { className: 'fw-semibold text-body-highlight text-end px-1' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Date',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end ps-3 pe-1' },
      cellProps: { className: 'fw-bold text-body-emphasis ps-3 text-end pe-1' }
    }
  },
  {
    accessorKey: 'project',
    header: 'Project',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    }
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    }
  },
  {
    accessorKey: 'due_date',
    header: 'Due Date',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  }
]

const ClientsTable = (props: {
  updateTable: boolean
  setUpdateTable: () => void
}) => {
  const [customers] = useState<Customer[]>([
    {
      id: '1',
      amount: '1000',
      customer: 'Customer 1',
      project: 'Project 1',
      tags: 'Tag 1, Tag 2',
      date: '01-01-2022',
      exp_date: '01-01-2022',
      refference: 'Refference 1',
      status: 'Paid'
    },
    {
      id: '2',
      amount: '2000',
      customer: 'Customer 2',
      project: 'Project 2',
      tags: 'Tag 1, Tag 2',
      date: '01-01-2022',
      exp_date: '01-01-2022',
      refference: 'Refference 2',
      status: 'Paid'
    },
    {
      id: '3',
      amount: '3000',
      customer: 'Customer 3',
      project: 'Project 3',
      tags: 'Tag 1, Tag 2',
      date: '01-01-2022',
      exp_date: '01-01-2022',
      refference: 'Refference 3',
      status: 'Paid'
    }
  ])
  const [preloader] = useState(false)

  useEffect(() => {
    // const API_REQUEST = new apiRequest(
    //   'https://demo.globalcloudmedia.com/client/get',
    //   true
    // )
    // API_REQUEST.send(
    //   {
    //     limit: 25,
    //     page: 0
    //   },
    //   (data: { status: string; message: string; data: Customer[] }) => {
    //     if (data.status == 'success') {
    //       setCustomers(data.data)
    //     } else {
    //       toastConfig({
    //         position: 'top-right',
    //         duration: 2000,
    //         theme: 'light'
    //       })
    //       toast(data.message)
    //     }
    //     setPreloader(false)
    //     props.setUpdateTable()
    //   }
    // )
  }, [props.updateTable])

  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    selection: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          preloader={preloader}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  )
}

export default ClientsTable
