import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup'
// import FilterTab, { FilterTabItem } from 'components/common/FilterTab'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import SearchBox from 'components/common/SearchBox'
import { customersTablecolumns } from 'components/tables/CustomersTable'
import { defaultBreadcrumbItems } from 'data/commonData'
import { customers } from 'data/e-commerce/customers'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import { ChangeEvent } from 'react'

import ExpensesTable from './components/ExpensesTable'
import { useState } from 'react'

const filterMenus: FilterMenu[] = [
  {
    label: 'Category',
    items: [
      {
        label: 'Furniture'
      },
      {
        label: 'Electronics'
      }
    ]
  }
]

const InvoicesList = () => {
  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  })

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined)
  }

  const [modelOpen, setModelOpen] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Expenses</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <div className="d-flex">
                <Button
                  variant="primary"
                  onClick={() => setModelOpen(true)}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add expense
                </Button>
              </div>
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <div className="ms-xxl-auto">
                <div className="d-flex flex-wrap gap-3">
                  <div className="d-flex">
                    <SearchBox
                      placeholder="Search expenses"
                      onChange={handleSearchInputChange}
                      className="text-right"
                    />
                    <Button
                      variant="secondary"
                      onClick={() => setModelOpen(true)}
                      className="ms-2"
                    >
                      <FontAwesomeIcon
                        icon={faFileExport}
                        className="fs-9 me-2 mr-1"
                      />
                      Export
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <ExpensesTable
              updateTable={updateTable}
              setUpdateTable={() => setUpdateTable(!modelOpen)}
            />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  )
}

export default InvoicesList
