import { Col, Row } from 'react-bootstrap'
import EcomTopRegions from './components/EcomTopRegions'
import FullCalendar from 'components/base/FullCalendar'
import CalendarProvider from 'providers/CalendarProvider'
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'components/base/DatePicker'

import StatisticsCards from './components/StatisticsCards'
import EcomProjectionVsActual from './components/EcomProjectionVsActual'
import TotalSales from './components/TotalSales'
import SalesTrends from './components/SalesTrends'

export const totalSales = [
  [60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70],
  [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50],
  [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
  [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
  [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50],
  [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70],
  [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50],
  [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110],
  [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70],
  [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110],
  [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70],
  [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70]
]

const ClientDashboard = () => {
  return (
    <>
      <Row className="mb-10" sm={12} md={12} lg={12} xl={12}>
        <Row xxl={12} sm={12} md={12} lg={12} xl={12}>
          <Col xxl={10} sm={12} md={12} lg={12} xl={12}>
            <WidgetsSectionTitle
              title="Sales Dashboard"
              subtitle="New Users & Leads"
              icon={faUsers}
              className="mb-5 mt-3"
            />
          </Col>
          <Col xxl={2} sm={12} md={12} lg={12} xl={12}>
            <DatePicker
              options={{
                mode: 'range',
                minDate: 'today',
                dateFormat: 'Y-m-d'
              }}
              placeholder="Select date"
            />
          </Col>
        </Row>
        <Row xxl={12} sm={12} md={12} lg={12} xl={12}>
          <Col xxl={12} sm={12} md={12} lg={12} xl={12}>
            <StatisticsCards />
          </Col>
        </Row>
        <Row xxl={12} sm={12} md={12} lg={12} xl={12} className="mt-4 mb-5">
          <Col xxl={7} sm={12} md={12} lg={12} xl={12}>
            <EcomProjectionVsActual />
            <TotalSales data={totalSales} />
          </Col>
          <Col xxl={5} sm={12} md={12} lg={12} xl={12}>
            <EcomTopRegions />
            <SalesTrends />
          </Col>
        </Row>
        <Row className="mt-0 mb-8">
          <Col xxl={12} sm={12} md={12} lg={12} xl={12}>
            <CalendarProvider>
              <FullCalendar
                height={800}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                buttonText={{
                  month: 'Month',
                  week: 'Week',
                  day: 'Day',
                  today: 'Today'
                }}
              />
            </CalendarProvider>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default ClientDashboard
