import ChatHomepageCard from 'components/cards/ChatHomepageCard'
import ChatSidebar from 'components/modules/chat/ChatSidebar'

const ChatHomepage = () => {
  return (
    <>
      <ChatSidebar />
      <ChatHomepageCard />
    </>
  )
}

export default ChatHomepage
