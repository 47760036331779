import { useState, ChangeEvent } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import ReactSelect from 'components/base/ReactSelect'
import toast from 'react-simple-toasts'

import { useNavigate } from 'react-router-dom'

import apiRequest from 'apiRequest'

const ClientContactBody = (props: {
  companyData: object
  setUpdateTable: () => void
  modelClose: () => void
}) => {
  const [postData, setPostData] = useState({
    profile_image: '',
    firstname: '',
    lastname: '',
    title: '',
    email: '',
    phonenumber: '',
    direction: 'ltr',
    password: '',
    is_primary: 1,
    donotsendwelcomeemail: 0,
    send_set_password_email: 0,
    permissions: '[1, 2, 3, 4, 5, 6]',
    contact_email_notifications: '[1, 2, 3, 4, 5, 6, 7]'
  })
  const navigate = useNavigate()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value
    })
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPostData({
      ...postData,
      [event.target.id]: event.target.value
    })
  }

  const handlePermissionChange = (newValue: unknown) => {
    if (Array.isArray(newValue)) {
      setPostData({
        ...postData,
        ['permissions']: JSON.stringify(
          Object(newValue).map((item: { value: string }) => {
            return item.value
          })
        )
      })
    }
  }

  const handleEmailNotifyChange = (newValue: unknown) => {
    if (Array.isArray(newValue)) {
      setPostData({
        ...postData,
        ['contact_email_notifications']: JSON.stringify(
          Object(newValue).map((item: { value: string }) => {
            return item.value
          })
        )
      })
    }
  }

  const saveClient = () => {
    props.modelClose()
    const tmpToast = toast('Saving', {
      duration: null,
      theme: 'info',
      loading: true
    })

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/add',
      true
    )

    API_REQUEST.send(
      Object.assign({}, props.companyData, {
        client: postData
      }),
      (data: { status: string; message: string; client: string }) => {
        if (data.status == 'success') {
          tmpToast?.update({
            message: data.message,
            loading: false,
            duration: 2000,
            theme: 'success'
          })

          props.setUpdateTable()
          setTimeout(() => {
            navigate('/module/clients/client/' + data.client, { replace: true })
          }, 100)
        } else {
          tmpToast?.update({
            message: data.message,
            loading: false,
            duration: 2000,
            theme: 'failure'
          })
        }

        if (data.status == 'denied') {
          setInterval(() => {
            navigate('/', { replace: true })
          }, 100)
        }
      }
    )
  }

  return (
    <Row className="gx-3 gy-4 mb-5">
      <Col xs={12} lg={4}>
        <h5 className="text-body-highlight mb-2">Profile image</h5>
        <Form.Control
          type="file"
          id="profile_image"
          accept="image/jpeg, image/png, image/jpg, image/gif, image/webp"
          //   onChange={handleChange}
        />
      </Col>
      <Col xs={12} lg={4}>
        <h5 className="text-body-highlight mb-2">First Name</h5>
        <Form.Control
          onChange={handleChange}
          type="text"
          id="firstname"
          placeholder="First Name"
        />
      </Col>
      <Col xs={12} lg={4}>
        <h5 className="text-body-highlight mb-2">Last Name</h5>
        <Form.Control
          onChange={handleChange}
          type="text"
          id="lastname"
          placeholder="Last Name"
        />
      </Col>
      <Col xs={12} lg={6}>
        <h5 className="text-body-highlight mb-2">Position</h5>
        <Form.Control
          onChange={handleChange}
          type="text"
          id="title"
          placeholder="Position"
        />
      </Col>
      <Col xs={12} lg={6}>
        <h5 className="text-body-highlight mb-2">Phone</h5>
        <Form.Control
          onChange={handleChange}
          type="text"
          id="phonenumber"
          placeholder="+1234567890"
        />
      </Col>
      <Col xs={12} lg={12}>
        <h5 className="text-body-highlight mb-2">Email</h5>
        <Form.Control
          onChange={handleChange}
          type="text"
          id="email"
          placeholder="Email"
        />
      </Col>
      <Col xs={12} lg={6}>
        <h5 className="text-body-highlight mb-2">Direction</h5>
        <Form.Select
          id="direction"
          name="direction"
          onChange={handleSelectChange}
        >
          <option value="ltr">LTR</option>
          <option value="rtl">RTL</option>
        </Form.Select>
      </Col>
      <Col xs={12} lg={6}>
        <h5 className="text-body-highlight mb-2">Password</h5>
        <Form.Control
          onChange={handleChange}
          type="password"
          id="password"
          placeholder="Password"
        />
      </Col>
      <Col xs={12} lg={4}>
        <Form.Check
          onChange={handleChange}
          type="switch"
          id="donotsendwelcomeemail"
          label="Do not send welcome email"
          value={1}
        />
      </Col>
      <Col xs={12} lg={4}>
        <Form.Check
          onChange={handleChange}
          type="switch"
          id="send_set_password_email"
          label="Send SET password email"
          value={1}
        />
      </Col>
      <Col xs={12} lg={4}>
        <Form.Check
          onChange={handleChange}
          type="switch"
          id="is_primary"
          label="Primary Contact"
          checked={true}
          value={1}
        />
      </Col>
      <Col xs={12} lg={12}>
        <h5 className="text-body-highlight mb-2">Permissions</h5>
        <ReactSelect
          defaultValue={[
            {
              value: '1',
              label: 'Invoices'
            },
            {
              value: '2',
              label: 'Estimates'
            },
            {
              value: '3',
              label: 'Contracts'
            },
            {
              value: '4',
              label: 'Proposals'
            },
            {
              value: '5',
              label: 'Support'
            },
            {
              value: '6',
              label: 'Projects'
            }
          ]}
          id="permissions"
          options={[
            {
              value: '1',
              label: 'Invoices'
            },
            {
              value: '2',
              label: 'Estimates'
            },
            {
              value: '3',
              label: 'Contracts'
            },
            {
              value: '4',
              label: 'Proposals'
            },
            {
              value: '5',
              label: 'Support'
            },
            {
              value: '6',
              label: 'Projects'
            }
          ]}
          isMulti
          placeholder="Select organizer..."
          onChange={handlePermissionChange}
        />
      </Col>
      <Col xs={12} lg={12}>
        <h5 className="text-body-highlight mb-2">Email Notifications</h5>
        <ReactSelect
          defaultValue={[
            {
              value: '1',
              label: 'Invoice'
            },
            {
              value: '2',
              label: 'Credit Note'
            },
            {
              value: '3',
              label: 'Tickets'
            },
            {
              value: '4',
              label: 'Task'
            },
            {
              value: '5',
              label: 'Estimate'
            },
            {
              value: '6',
              label: 'Project'
            },
            {
              value: '7',
              label: 'Contract'
            }
          ]}
          id="groups"
          options={[
            {
              value: '1',
              label: 'Invoice'
            },
            {
              value: '2',
              label: 'Credit Note'
            },
            {
              value: '3',
              label: 'Tickets'
            },
            {
              value: '4',
              label: 'Task'
            },
            {
              value: '5',
              label: 'Estimate'
            },
            {
              value: '6',
              label: 'Project'
            },
            {
              value: '7',
              label: 'Contract'
            }
          ]}
          isMulti
          placeholder="Select organizer..."
          onChange={handleEmailNotifyChange}
        />
      </Col>
      <Col xs={12} lg={8}></Col>
      <Col xs={12} lg={4} style={{ textAlign: 'right' }}>
        <Button variant="primary" type="submit" onClick={saveClient}>
          Save & Create Contact
        </Button>
      </Col>
    </Row>
  )
}

export default ClientContactBody
