import { ColumnDef } from '@tanstack/react-table'
import AdvanceTable from 'components/base/AdvanceTable'
// import { Link } from 'react-router-dom'
import AdvanceTableFooter from 'components/base/AdvanceTableFooter'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import useAdvanceTable from 'hooks/useAdvanceTable'

import { useEffect, useState } from 'react'

// import apiRequest from 'apiRequest'
// import toast, { toastConfig } from 'react-simple-toasts'

export type Customer = {
  id: string
  subject: string
  to: string
  total: string
  date: string
  project: string
  open_till: string
  tags: string
  date_created: string
  status?: string
}

const customersTablecolumns: ColumnDef<Customer>[] = [
  {
    accessorKey: 'id',
    header: 'Proposal #',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-5 ps-0' },
      cellProps: { className: 'py-0 pe-5 ps-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'subject',
    header: 'Subject',
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    }
  },
  {
    accessorKey: 'to',
    header: 'To',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
      cellProps: { className: 'fw-semibold text-body-highlight text-end px-1' }
    }
  },
  {
    accessorKey: 'total',
    header: 'Total',
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end ps-3 pe-1' },
      cellProps: { className: 'fw-bold text-body-emphasis ps-3 text-end pe-1' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Date',
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-7' },
      cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
    }
  },
  {
    accessorKey: 'open_till',
    header: 'Open Till',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    }
  },
  {
    accessorKey: 'project',
    header: 'Project',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    }
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  },
  {
    accessorKey: 'date_created',
    header: 'Date Created',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  }
]

const ClientsTable = (props: {
  updateTable: boolean
  setUpdateTable: () => void
}) => {
  const [customers] = useState<Customer[]>([
    {
      id: '1',
      subject: 'Subject 1',
      to: 'admin',
      total: '$100.00',
      date: '25 May, 2024',
      open_till: '29 May, 2024',
      project: 'Project 1',
      tags: 'Tags1, Tags2, Tags3',
      date_created: '19 May, 2024',
      status: 'Open'
    },
    {
      id: '2',
      subject: 'Subject 2',
      to: 'admin',
      total: '$200.00',
      date: '25 May, 2024',
      open_till: '29 May, 2024',
      project: 'Project 2',
      tags: 'Tags1, Tags2, Tags3',
      date_created: '19 May, 2024',
      status: 'Open'
    },
    {
      id: '3',
      subject: 'Subject 3',
      to: 'admin',
      total: '$300.00',
      date: '25 May, 2024',
      open_till: '29 May, 2024',
      project: 'Project 3',
      tags: 'Tags1, Tags2, Tags3',
      date_created: '19 May, 2024',
      status: 'Open'
    }
  ])
  const [preloader] = useState(false)

  useEffect(() => {
    // const API_REQUEST = new apiRequest(
    //   'https://demo.globalcloudmedia.com/client/get',
    //   true
    // )
    // API_REQUEST.send(
    //   {
    //     limit: 25,
    //     page: 0
    //   },
    //   (data: { status: string; message: string; data: Customer[] }) => {
    //     if (data.status == 'success') {
    //       setCustomers(data.data)
    //     } else {
    //       toastConfig({
    //         position: 'top-right',
    //         duration: 2000,
    //         theme: 'light'
    //       })
    //       toast(data.message)
    //     }
    //     setPreloader(false)
    //     props.setUpdateTable()
    //   }
    // )
  }, [props.updateTable])

  const table = useAdvanceTable({
    data: customers,
    columns: customersTablecolumns,
    selection: true,
    sortable: true
  })

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          preloader={preloader}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  )
}

export default ClientsTable
