import useConfigMountEffect from 'hooks/useConfigMountEffect'
import useSettingsMountEffect from 'hooks/useSettingsMountEffect'
import Ecommerce from 'pages/dashboard/ecommerce'

const ComboNavSlim = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true,
    disableResetButton: true
  })

  useConfigMountEffect({
    navbarPosition: 'combo',
    navbarTopShape: 'slim'
  })

  return <Ecommerce />
}

export default ComboNavSlim
