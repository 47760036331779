import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Icon, UilFilesLandscapesAlt } from '@iconscout/react-unicons'

export interface Route {
  name: string
  icon?: IconProp | string | string[]
  iconSet?: 'font-awesome' | 'feather' | 'unicons'
  pages?: Route[]
  path?: string
  pathName?: string
  flat?: boolean
  topNavIcon?: string
  dropdownInside?: boolean
  active?: boolean
  new?: boolean
  hasNew?: boolean
}

export interface RouteItems {
  label: string
  horizontalNavLabel?: string
  icon: Icon
  labelDisabled?: boolean
  pages: Route[]
  megaMenu?: boolean
  active?: boolean
}

export const routes: RouteItems[] = [
  {
    label: 'Main',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'Dashboard',
        icon: 'bar-chart',
        path: '/dashboard',
        pathName: 'dashboard',
        active: true
      },
      {
        name: 'calendar',
        icon: 'calendar',
        path: 'apps/calendar',
        pathName: 'app-calendar',
        active: true
      }
    ]
  },
  {
    label: 'Sections',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'clients',
        active: true,
        icon: 'user',
        pages: [
          {
            name: 'Dashboard',
            path: '/module/clients/dashboard',
            pathName: 'client-dashboard',
            active: true
          },
          {
            name: 'Client List',
            path: '/module/clients/list',
            pathName: 'clients',
            active: true
          }
        ]
      },
      {
        name: 'Sales',
        active: true,
        icon: 'pie-chart',
        pages: [
          {
            name: 'Dashboard',
            path: '/module/sales/dashboard',
            pathName: 'sales-dashboard',
            active: true
          },
          {
            name: 'Proposals',
            path: '/module/sales/proposals',
            pathName: 'sales-proposals',
            active: true
          },
          {
            name: 'Estimates',
            path: '/module/sales/estimates',
            pathName: 'sales-estimates',
            active: true
          },
          {
            name: 'Invoices',
            path: '/module/sales/invoices',
            pathName: 'sales-invoices',
            active: true
          },
          {
            name: 'Proforma Invoice',
            path: '/module/sales/profoma-invoices',
            pathName: 'sales-invoices',
            active: true
          },
          {
            name: 'Payments',
            path: '/module/sales/payments',
            pathName: 'sales-payments',
            active: true
          },
          {
            name: 'Credit Notes',
            path: '/module/sales/credit-notes',
            pathName: 'sales-credit-notes',
            active: true
          },
          {
            name: 'Items',
            path: '/module/sales/items',
            pathName: 'sales-items',
            active: true
          }
        ]
      },
      {
        name: 'Accounting',
        active: true,
        icon: 'book-open',
        pages: [
          {
            name: 'Dashboard',
            path: '/module/sales/dashboard',
            pathName: 'sales-dashboard',
            active: true
          },
          {
            name: 'Banking',
            path: '/module/sales/proposals',
            pathName: 'sales-proposals',
            active: true
          },
          {
            name: 'Transactions',
            path: '/module/sales/estimates',
            pathName: 'sales-estimates',
            active: true
          },
          {
            name: 'Journal Entry',
            path: '/module/sales/invoices',
            pathName: 'sales-invoices',
            active: true
          },
          {
            name: 'Transfer',
            path: '/module/sales/profoma-invoices',
            pathName: 'sales-invoices',
            active: true
          },
          {
            name: 'Chart of Accounts',
            path: '/module/sales/payments',
            pathName: 'sales-payments',
            active: true
          },
          {
            name: 'Reconcile',
            path: '/module/sales/credit-notes',
            pathName: 'sales-credit-notes',
            active: true
          },
          {
            name: 'Budget',
            path: '/module/sales/items',
            pathName: 'sales-items',
            active: true
          },
          {
            name: 'Reports',
            path: '/module/sales/items',
            pathName: 'sales-items',
            active: true
          },
          {
            name: 'Settings',
            path: '/module/sales/items',
            pathName: 'sales-items',
            active: true
          }
        ]
      },
      {
        name: 'Expenses',
        active: true,
        icon: 'dollar-sign',
        path: '/expenses',
        pathName: 'expenses'
      },
      {
        name: 'Purchase',
        active: true,
        icon: 'shopping-cart',
        pages: [
          {
            name: 'Dashboard',
            path: '#purchase-dashboard',
            pathName: 'purchase-dashboard',
            active: true
          },
          {
            name: 'Items',
            path: '#purchase-items',
            pathName: 'purchase-items',
            active: true
          },
          {
            name: 'Vendors',
            path: '#purchase-vendors',
            pathName: 'purchase-vendors',
            active: true
          },
          {
            name: 'Purchase Request',
            path: '#purchase-requests',
            pathName: 'purchase-requests',
            active: true
          },
          {
            name: 'Purchase Order',
            path: '#purchase-order',
            pathName: 'purchase-order',
            active: true
          },
          {
            name: 'Order Returns',
            path: '#purchase-order-returns',
            pathName: 'purchase-order-returns',
            active: true
          },
          {
            name: 'Quotations',
            path: '#purchase-quotations',
            pathName: 'purchase-quotations',
            active: true
          },
          {
            name: 'Debit Notes',
            path: '#purchase-debit-notes',
            pathName: 'purchase-debit-notes',
            active: true
          },
          {
            name: 'Invoices',
            path: '#purchase-invoices',
            pathName: 'purchase-invoices',
            active: true
          },
          {
            name: 'Reports',
            path: '#purchase-reports',
            pathName: 'purchase-reports',
            active: true
          },
          {
            name: 'Settings',
            path: '#purchase-settings',
            pathName: 'purchase-settings',
            active: true
          }
        ]
      },
      {
        name: 'Inventory',
        active: true,
        icon: 'shopping-bag',
        pages: [
          {
            name: 'Dashboard',
            path: '#inventory-dashboard',
            pathName: 'inventory-dashboard',
            active: true
          },
          {
            name: 'Items',
            path: '#inventory-items',
            pathName: 'inventory-items',
            active: true
          },
          {
            name: 'Inventory receiving voucher',
            path: '#inventory-receiving-voucher',
            pathName: 'inventory-receiving-voucher',
            active: true
          },
          {
            name: 'Inventory delivery voucher',
            path: '#inventory-delivery-voucher',
            pathName: 'inventory-delivery-voucher',
            active: true
          },
          {
            name: 'Packing lists',
            path: '#inventory-packing-lists',
            pathName: 'inventory-packing-lists',
            active: true
          },
          {
            name: 'Internal delivery note',
            path: '#inventory-delivery-notes',
            pathName: 'inventory-delivery-notes',
            active: true
          },
          {
            name: 'Loss & Adjustment',
            path: '#inventory-loss-adjustment',
            pathName: 'inventory-loss-adjustment',
            active: true
          },
          {
            name: 'Return orders',
            path: '#inventory-return-orders',
            pathName: 'inventory-return-orders',
            active: true
          },
          {
            name: 'Reports',
            path: '#inventory-reports',
            pathName: 'inventory-reports',
            active: true
          },
          {
            name: 'Settings',
            path: '#inventory-settings',
            pathName: 'inventory-settings',
            active: true
          }
        ]
      },
      {
        name: 'project-management',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'Dashboard',
            path: '/dashboard/project-management',
            pathName: 'project-management-dashboard',
            active: true
          },
          {
            name: 'create new',
            path: '/apps/project-management/create-new',
            pathName: 'project-management-create-new',
            active: true
          },
          {
            name: 'project-list-view',
            path: '/apps/project-management/project-list-view',
            pathName: 'project-management-project-list-view',
            active: true
          },
          {
            name: 'project-card-view',
            path: '/apps/project-management/project-card-view',
            pathName: 'project-management-project-card-view',
            active: true
          },
          {
            name: 'project-board-view',
            path: '/apps/project-management/project-board-view',
            pathName: 'project-management-project-board-view',
            active: true
          },
          {
            name: 'todo-list',
            path: '/apps/project-management/todo-list',
            pathName: 'project-management-todo-list',
            active: true
          },
          {
            name: 'project-details',
            path: '/apps/project-management/project-details',
            pathName: 'project-management-project-details',
            active: true
          }
        ]
      },
      {
        name: 'Tasks',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'Dashboard',
            path: '#tasks-dashboard',
            pathName: 'tasks-dashboard',
            active: true
          },
          {
            name: 'Tasks List',
            path: '#tasks-list',
            pathName: 'tasks-list',
            active: true
          }
        ]
      },
      {
        name: 'Support',
        active: true,
        icon: 'help-circle',
        path: '#ticket',
        pathName: 'ticket'
      },
      {
        name: 'Agreements',
        icon: 'align-justify',
        active: true,
        pages: [
          {
            name: 'Dashboard',
            path: '#agreements-dashboard',
            pathName: 'agreements-dashboard',
            active: true
          },
          {
            name: 'Agreements List ',
            path: '#agreements-list',
            pathName: 'agreements-list',
            active: true
          }
        ]
      },
      {
        name: 'events',
        icon: 'bookmark',
        active: true,
        pages: [
          {
            name: 'create an event',
            path: '/apps/events/create-an-event',
            pathName: 'create-an-event',
            active: true
          },
          {
            name: 'Event detail',
            path: '/apps/events/event-detail',
            pathName: 'event-detail',
            active: true
          }
        ]
      },
      {
        name: 'Timesheet & Leave',
        active: true,
        icon: 'clock',
        pages: [
          {
            name: 'Attendance',
            path: '#timesheet-leave-dashboard',
            pathName: 'timesheet-leave-dashboard',
            active: true
          },
          {
            name: 'Attendance',
            path: '#timesheet-leave-attendances',
            pathName: 'timesheet-leave-attendances',
            active: true
          },
          {
            name: 'Leave',
            path: '#timesheet-leave-leave',
            pathName: 'timesheet-leave-leave',
            active: true
          },
          {
            name: 'Work Shift Table',
            path: '#timesheet-leave-work-shift-table',
            pathName: 'timesheet-leave-work-shift-table',
            active: true
          },
          {
            name: 'Shift',
            path: '#timesheet-leave-shift',
            pathName: 'timesheet-leave-shift',
            active: true
          },
          {
            name: 'Shift Categories',
            path: '#timesheet-leave-shift-categories',
            pathName: 'timesheet-leave-shift-categories',
            active: true
          },
          {
            name: 'Reports',
            path: '#timesheet-leave-reports',
            pathName: 'timesheet-leave-reports',
            active: true
          },
          {
            name: 'Settings',
            path: '#timesheet-leave-settings',
            pathName: 'timesheet-leave-settings',
            active: true
          }
        ]
      },
      {
        name: 'HR Management',
        active: true,
        icon: 'user-plus',
        pages: [
          {
            name: 'Dashboard',
            path: '#hr-dashboard',
            pathName: 'hr-dashboard',
            active: true
          },
          {
            name: 'Job Positions',
            path: '#job_positions',
            pathName: 'job-positions',
            active: true
          },
          {
            name: 'Organizational Chart',
            path: '#organizational_chart',
            pathName: 'organizational-chart',
            active: true
          },
          {
            name: 'Onboarding',
            path: '#reception_staff',
            pathName: 'reception-staff',
            active: true
          },
          {
            name: 'HR Records',
            path: '#staff_infor',
            pathName: 'staff-infor',
            active: true
          },
          {
            name: 'Training',
            path: '#training_program',
            pathName: 'training-program',
            active: true
          },
          {
            name: 'Contracts',
            path: '#contracts',
            pathName: 'contracts',
            active: true
          },
          {
            name: 'Dependent Persons',
            path: '#dependent_persons',
            pathName: 'dependent-persons',
            active: true
          },
          {
            name: 'Layoff Checklist',
            path: '#layoff_checklist',
            pathName: 'layoff-checklist',
            active: true
          },
          {
            name: 'Q&A',
            path: '#knowledge_base',
            pathName: 'knowledge-base',
            active: true
          }
        ]
      },
      {
        name: 'Payroll Management',
        active: true,
        icon: 'dollar-sign',
        pages: [
          {
            name: 'Dashboard',
            path: '#payroll-dashboard',
            pathName: 'payroll-dashboard',
            active: true
          },
          {
            name: 'Employees',
            path: '#manage_employees',
            pathName: 'manage-employees',
            active: true
          },
          {
            name: 'Attendance',
            path: '#manage_attendance',
            pathName: 'manage-attendance',
            active: true
          },
          {
            name: 'Commissions',
            path: '#manage_commissions',
            pathName: 'manage-commissions',
            active: true
          },
          {
            name: 'Deductions',
            path: '#deductions',
            pathName: 'deductions',
            active: true
          },
          {
            name: 'Bonuse Kpi',
            path: '#bonuses_kpi',
            pathName: 'bonuses-kpi',
            active: true
          },
          {
            name: 'Insurance',
            path: '#insurance',
            pathName: 'insurance',
            active: true
          },
          {
            name: 'Payslip',
            path: '#payslip',
            pathName: 'payslip',
            active: true
          },
          {
            name: 'Payslip Templates',
            path: '#payslip_templates',
            pathName: 'payslip-templates',
            active: true
          },
          {
            name: 'Income Taxes',
            path: '#income_taxes',
            pathName: 'income-taxes',
            active: true
          }
        ]
      },
      {
        name: 'Payroll',
        active: true,
        icon: 'dollar-sign',
        path: '#payroll',
        pathName: 'payroll'
      },
      {
        name: 'Subscriptions',
        active: true,
        icon: 'rss',
        path: '#subscribe',
        pathName: 'subscriptions'
      },
      {
        name: 'Support',
        active: true,
        icon: 'help-circle',
        path: '#ticket',
        pathName: 'ticket'
      },
      {
        name: 'Manufacturing',
        active: true,
        icon: 'truck',
        path: '#manufacturing',
        pathName: 'manufacturing'
      },
      {
        name: 'Asset Management',
        active: true,
        icon: 'box',
        path: '#asset_management',
        pathName: 'asset-management'
      },
      {
        name: 'Leads Management',
        active: true,
        icon: 'users',
        path: '#leads_management',
        pathName: 'leads-management'
      },
      {
        name: 'Estimate Request',
        active: true,
        icon: 'file',
        path: '#estimate_request',
        pathName: 'estimate-request'
      },
      {
        name: 'POS',
        active: true,
        icon: 'dollar-sign',
        path: '#pos',
        pathName: 'pos'
      },
      {
        name: 'Marketing',
        active: true,
        icon: 'at-sign',
        path: '#marketing',
        pathName: 'marketing'
      },
      {
        name: 'Affiliate Management',
        active: true,
        icon: 'star',
        path: '#affiliate_management',
        pathName: 'affiliate-management'
      },
      {
        name: 'Sales Agent Management',
        active: true,
        icon: 'phone',
        path: '#sales_agent_management',
        pathName: 'sales-agent-management'
      },
      {
        name: 'Customer Loyalty Points',
        active: true,
        icon: 'gift',
        path: '#customer_loyalty_points',
        pathName: 'customer-loyalty-points'
      },
      {
        name: 'Meeting Management',
        active: true,
        icon: 'calendar',
        path: '#meeting_management',
        pathName: 'meeting-management'
      },
      {
        name: 'Announcement Management',
        active: true,
        icon: 'bell',
        path: '#announcement_management',
        pathName: 'announcement-management'
      },
      {
        name: 'Document Management',
        active: true,
        icon: 'file',
        path: '#document_management',
        pathName: 'document-management'
      },
      {
        name: 'Maintenance Managament',
        active: true,
        icon: 'edit',
        path: '#maintenance_management',
        pathName: 'maintenance-management'
      },
      {
        name: 'Work Planning',
        active: true,
        icon: 'clipboard',
        path: '#work_planning',
        pathName: 'work-planning'
      }
    ]
  },
  {
    label: 'Chat And Communication',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'chat',
        icon: 'message-square',
        path: '/apps/chat',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'email',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'inbox',
            path: '/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'email-detail',
            path: '/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'compose',
            path: '/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'social',
        icon: 'share-2',
        active: true,
        pages: [
          {
            name: 'profile',
            path: '/apps/social/profile',
            pathName: 'social-profile',
            active: true
          },
          {
            name: 'settings',
            path: '/apps/social/settings',
            pathName: 'settings',
            active: true
          }
        ]
      }
    ]
  }
]
