import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, ChangeEvent, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import WizardFormFooter from 'components/wizard/WizardFormFooter'
import WizardForm from 'components/wizard/WizardForm'
import useWizardForm from 'hooks/useWizardForm'
import WizardFormProvider from 'providers/WizardFormProvider'
import { Card, Tab } from 'react-bootstrap'
import classNames from 'classnames'
import { Nav } from 'react-bootstrap'
import { useWizardFormContext } from 'providers/WizardFormProvider'

import ClientContactBody from './ClientContactBody'

import ReactSelect from 'components/base/ReactSelect'

interface WizardFormData {
  company_name: string
}

const WizardNavItem = ({
  icon,
  label,
  step
}: {
  icon: IconProp
  label: string
  step: number
}) => {
  const { selectedStep, totalStep } = useWizardFormContext()
  return (
    <Nav.Item as="li" className="nav-item">
      <Nav.Link
        className={classNames('fw-semibold', {
          done: selectedStep > step && step !== totalStep,
          complete: selectedStep > step && step !== totalStep - 1
        })}
        eventKey={step}
      >
        <div className="text-center d-inline-block">
          <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={icon} />
            </span>
          </span>
          <span className="d-none d-md-block mt-1 fs-9">{label}</span>
        </div>
      </Nav.Link>
    </Nav.Item>
  )
}

const ClientAddBody = (props: {
  modelClose: () => void
  setUpdateTable: () => void
}) => {
  const [postData, setPostData] = useState({
    company_name: '',
    vat_number: '',
    website: '',
    phone: '',
    address: '',
    groups: '[]',
    currency: 'lkr',
    default_language: 'en',
    city: '',
    state: '',
    country: ''
  })
  const [groups, setGroups] = useState<string>('[]')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value
    })
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPostData({
      ...postData,
      [event.target.id]: event.target.value
    })
  }

  const handleMultiSelectChange = (newValue: unknown) => {
    if (Array.isArray(newValue)) {
      setGroups(
        JSON.stringify(
          Object(newValue).map((item: { value: string }) => {
            return item.value
          })
        )
      )
    }
  }

  useEffect(() => {
    if (groups !== '[]') {
      setPostData({
        ...postData,
        ['groups']: groups
      })
    }
  }, [groups])

  const form = useWizardForm<WizardFormData>({
    totalStep: 2
  })

  return (
    <div>
      <WizardFormProvider {...form}>
        <Card className="theme-wizard">
          <Card.Header className="bg-body-highlight pt-3 pb-2 border-bottom-0">
            <Nav className="justify-content-between nav-wizard">
              <WizardNavItem icon={faLock} step={1} label="Company" />
              <WizardNavItem
                icon={faUser}
                step={2}
                label="Contact Information"
              />
            </Nav>
          </Card.Header>
          <Card.Body>
            <Tab.Content>
              <Tab.Pane eventKey={1}>
                <WizardForm step={1}>
                  <Row className="gx-3 gy-4 mb-5">
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Company Name</h5>
                      <Form.Control
                        type="text"
                        id="company_name"
                        onChange={handleChange}
                        placeholder="Company Name"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">VAT Number</h5>
                      <Form.Control
                        onChange={handleChange}
                        type="text"
                        id="vat_number"
                        placeholder="VAT Number"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Website</h5>
                      <Form.Control
                        onChange={handleChange}
                        type="text"
                        id="website"
                        placeholder="Website"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Phone</h5>
                      <Form.Control
                        onChange={handleChange}
                        type="text"
                        id="phone"
                        placeholder="+1234567890"
                      />
                    </Col>
                    <Col xs={12} lg={12}>
                      <h5 className="text-body-highlight mb-2">Groups</h5>
                      <ReactSelect
                        id="groups"
                        options={[
                          {
                            value: '1',
                            label: 'Abc'
                          }
                        ]}
                        isMulti
                        placeholder="Select organizer..."
                        onChange={handleMultiSelectChange}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Currency</h5>
                      <Form.Select id="currency" onChange={handleSelectChange}>
                        <option value="lk">LK</option>
                        <option value="usd">USD</option>
                      </Form.Select>
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">
                        Default Language
                      </h5>
                      <Form.Select
                        id="default_language"
                        onChange={handleSelectChange}
                      >
                        <option value="male">EN</option>
                      </Form.Select>
                    </Col>
                    <Col xs={12} lg={12}>
                      <h5 className="text-body-highlight mb-2">Address</h5>
                      <Form.Control
                        onChange={handleChange}
                        id="address"
                        as="textarea"
                        placeholder="Address"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">City</h5>
                      <Form.Control
                        id="city"
                        onChange={handleChange}
                        type="text"
                        placeholder="City"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">State</h5>
                      <Form.Control
                        id="state"
                        onChange={handleChange}
                        type="text"
                        placeholder="State"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Zip Code</h5>
                      <Form.Control
                        id="zip_code"
                        onChange={handleChange}
                        type="text"
                        placeholder="Zip Code"
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="text-body-highlight mb-2">Country</h5>
                      <Form.Select id="country" onChange={handleSelectChange}>
                        <option value="1">Sri Lanka</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </WizardForm>
              </Tab.Pane>
              <Tab.Pane eventKey={2}>
                <WizardForm step={2}>
                  {
                    <ClientContactBody
                      companyData={postData}
                      modelClose={props.modelClose}
                      setUpdateTable={props.setUpdateTable}
                    />
                  }
                </WizardForm>
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
          <Card.Footer className="border-top-0 p-0">
            <WizardFormFooter
              className={classNames({ 'd-none': !form.getCanNextPage })}
            />
          </Card.Footer>
        </Card>
      </WizardFormProvider>
    </div>
  )
}

export default ClientAddBody
