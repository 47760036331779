import EcomTopRegionsTable from './EcomTopRegionsTable'
import React from 'react'

const EcomTopRegions = () => {
  return (
    <>
      <div className="mb-5 mt-0">
        <h3>Recent Transactions Order</h3>
        <p className="text-body-tertiary">
          {' '}
          Which invoices gained most of the revenue
        </p>
      </div>
      <EcomTopRegionsTable />
    </>
  )
}

export default EcomTopRegions
