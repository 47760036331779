import ForgotPasswordForm from 'components/modules/auth/ForgotPasswordForm'
import AuthCardLayout from 'layouts/AuthCardLayout'

const ForgotPassword = () => {
  return (
    <AuthCardLayout>
      <ForgotPasswordForm />
    </AuthCardLayout>
  )
}

export default ForgotPassword
